import styled  from "styled-components";
import { BoundedContent } from "../../BoundedContent";
import { VisibleContainer } from "../../VisibleContainer";
import details2 from "./details-2.png";
const video = require("./details-1.mp4"); 

const Container = styled(BoundedContent)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Row = styled.div<{ isReverse? : boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${props => props.theme.spacing.large};

  ${props => props.theme.breakpoints.tablet} {
    flex-direction: ${props => props.isReverse ? "row-reverse" : "row"};
  gap: ${props => props.theme.spacing.xlarge};
  }
`

const VisibleBlock = styled(VisibleContainer)`
max-width: 400px;
width: 100%;
${props => props.theme.breakpoints.tablet} {
  width: calc(50% - ${props => props.theme.spacing.xlarge} / 2);
}
`

const Image = styled.img`
width: 100%;
`

const Video = styled.video`
width: 100%;
`

const TextBlockContainer = styled.div`
  gap: ${props => props.theme.spacing.medium};
`

const TextBlockTitle = styled.h3`
font-weight: bold;
font-size: ${props => props.theme.spacing.medium};
${props => props.theme.breakpoints.medium} {
  font-size: ${props => props.theme.spacing.large};
}
`

const TextBlockDescription = styled.p`
font-size: ${props => props.theme.spacing.small};
${props => props.theme.breakpoints.medium} {
font-size: ${props => props.theme.spacing.medium};
}
`

function TextBlock ({ title, description }: { title: string; description: string }) {
  return <TextBlockContainer >
      <TextBlockTitle>{title}</TextBlockTitle>
      <TextBlockDescription>{description}</TextBlockDescription>
  </TextBlockContainer>
}

export function Details () {
  return (
    <Container>
      <Row>
      <VisibleBlock fullVisibleHeightPercentage={0.8}>
        <TextBlock
          title="Graphical data processing"
          description="By dragging-n-dropping nodes, Zora enables processing data and build programs in an interactive and visual fashion, allowing you to focus more on what really matters."
        />
      </VisibleBlock>
      <VisibleBlock fullVisibleHeightPercentage={0.8}>
        <Video controls>
          <source src={video} type="video/mp4" />
        </Video>
      </VisibleBlock>
      </Row>
      <Row isReverse>
        <VisibleBlock fullVisibleHeightPercentage={0.8}>
          <TextBlock
            title="Advanced function packs"
            description="Make dashboards, automatic tasks, process images and videos, integrate LLM services and more - all available within a few mouse clicks. You can create programs on your own, or let AI design it for you."
          />
        </VisibleBlock>
        <VisibleBlock fullVisibleHeightPercentage={0.8}>
          <Image alt="" src={details2}/>
        </VisibleBlock>
      </Row>
    </Container>
  );
}
